import { render, staticRenderFns } from "./CharacterAvatarItem.vue?vue&type=template&id=73829288&scoped=true"
import script from "./CharacterAvatarItem.vue?vue&type=script&lang=js"
export * from "./CharacterAvatarItem.vue?vue&type=script&lang=js"
import style0 from "./CharacterAvatarItem.vue?vue&type=style&index=0&id=73829288&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73829288",
  null
  
)

export default component.exports